.q-card, .rounded-borders,
  //.q-field--outlined .q-field__control
{
  border-radius: 20px;
}

.c-btn--outstanding .q-field__control {
  padding: 0 20px;

  &:before {
    border-style: solid !important;
  }
}

.q-btn--rounded {
  border-radius: 28px;
}

.q-dialog__backdrop {
  backdrop-filter: blur(3px);
}


.q-dialog__inner > div {
  border-radius: 20px;
}
