@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@import 'layout';
@import 'swiper';
@import 'overwrites';
@import 'kredit-widget.css';

@font-face {
  font-family: Coiny;
  src: local('Coiny'), url("./fonts/Coiny-Regular.ttf") format('truetype');
  font-display: swap;
}

@font-face {
  font-family: FjallaOne;
  src: local('FjallaOne-Regular'), url("./fonts/FjallaOne-Regular.ttf") format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: local('Inter'), url("./fonts/Inter-Regular.ttf") format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "Inter Bold";
  src: local('Inter Bold'), url("./fonts/Inter-Bold.ttf") format('truetype');
  font-display: swap;
}

.font-inter {
  font-family: Inter, sans-serif;
}

.font-inter-bold, .font-inter.text-bold {
  font-family: "Inter Bold", sans-serif;
}

.font-coiny {
  font-family: Coiny, sans-serif;
}

.font-fjalla-one {
  font-family: FjallaOne, sans-serif;
}

body, html, a {
  color: #373D3F;
  word-wrap: break-word;
}

body {
  background-color: #ffffff;
}

.text-black {
  color: $text-black !important;
}

.bg-black {
  background-color: $text-black !important;
}

.text-light {
  color: $light !important;
}

.bg-light {
  background-color: $light !important;
}

.vhtml-container {
  p {
    padding: unset;
    margin: unset;
  }
}

.text-muted {
  color: $grey-6;
}


.text-small {
  font-size: small;
}

.text-xsmall {
  font-size: smaller;
}


.text-tertiary {
  color: $tertiary;
}

.bg-tertiary {
  background-color: $tertiary;
}

[dark]:not([dark="false"]) {
  /* Change the background color of inputs on Chrome autofill */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $dark inset !important;
  }

  /* Change the text color of inputs on Chrome autofill */
  input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
  }
}

.bordered {
  border: 1px solid #DDDDDD;
}

.fa-google.colored {
  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.c-btn--special {
  color: white;
  background-image: radial-gradient(
      circle at center,
      adjust-color($secondary, $lightness: -3%) 75%,
      adjust-color($secondary, $lightness: 1%) 27.5%,
      adjust-color($secondary, $lightness: 3%) 40%,
      adjust-color($secondary, $lightness: 1%) 57.5%,
      adjust-color($secondary, $lightness: -3%) 75%,
      adjust-color($secondary, $lightness: -4%) 100%
  );
  background-size: 100% 100%;
  transition: opacity 1.25s;
  opacity: 1;

  &.disabled {
    background: adjust-color($secondary, $lightness: -15%);
    opacity: 0.4 !important;
  }
}


h1, h2, h3, h4, h5, h6 {
  margin: 0;
}


// fix ios app
.header__fix:last-of-type, .header__fix__force {
  padding-top: constant(safe-area-inset-top); /* for iOS 11.0 */
  padding-top: env(safe-area-inset-top);      /* for iOS 11.2+ */
}

/* For your app's footer */
.footer__fix {
  padding-bottom: constant(safe-area-inset-bottom); /* for iOS 11.0 */
  padding-bottom: env(safe-area-inset-bottom);      /* for iOS 11.2+ */
}

.disable-long-press {
  -webkit-touch-callout: none; /* Disable callout, which is the preview link modal */
}


.c-fancy-background {
  min-height: 250px;
  border: 1px solid #e4e4e4;
  background-color: #fafafa;
  opacity: 1;
  background-image: linear-gradient(135deg, #ffffff 25%, transparent 25%), linear-gradient(225deg, #ffffff 25%, transparent 25%), linear-gradient(45deg, #ffffff 25%, transparent 25%), linear-gradient(315deg, #ffffff 25%, #fafafa 25%);
  background-position: 8px 0, 8px 0, 0 0, 0 0;
  background-size: 16px 16px;
  background-repeat: repeat;
}
