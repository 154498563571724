

@keyframes cfadeIn {
  100% {
    opacity: 1;
  }
}

@keyframes organicPulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}


.c-logo {
  animation: organicPulse 1.3s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);;
}

.c-text {
  opacity: 0;
  animation: cfadeIn 2s ease-out forwards;
  animation-delay: 0.2s;
}
