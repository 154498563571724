// breakpoint variable, from https://quasar.dev/style/breakpoints
$xs-breakpoint: 599px;
$sm-breakpoint: 1023px;
$md-breakpoint: 1439px;
$lg-breakpoint: 1919px;

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl,
{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1120px !important;
}

.container-wide {
  max-width: 2048px !important;
  margin: auto;
}

@media (max-width: $breakpoint-xs) {
  .container,
  .container-wide {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: $breakpoint-xs) {
  .container,
  .container-sm,
  .container-wide {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: $breakpoint-sm) {
  .container,
  .container-sm,
  .container-md,
  .container-wide {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: $breakpoint-md) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-wide {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: $breakpoint-lg) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-wide {
    padding-left: 8px;
    padding-right: 8px;
  }
}


.container-full-bleed {
  margin-left: -8px;
  margin-right: -8px;
}
