
:root {
  --swiper-theme-color: black;

  --swiper-navigation-color: white;
  --swiper-navigation-size: 12px;

  --swiper-pagination-bullet-inactive-color: white;
  --swiper-pagination-bullet-inactive-opacity: 0.45;
  --swiper-pagination-bullet-opacity: 0.85;
  --swiper-pagination-bullet-color: white;
  --swiper-pagination-color: white;

  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;
}


swiper-container::part(button-prev),
swiper-container::part(button-next),
{
  padding: 6px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  color: $dark;
  opacity: 0.65;

  &:hover {
    opacity: 1;
  }
}
